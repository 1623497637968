.main-footer{
    /* margin-top: 1rem; */
    /* padding: 1rem; */
    color:whitesmoke;
    background-color:rgb(6, 24, 39);
    box-shadow: inset 0 0.2 0.2 0.8 grey;
    position:relative;
    bottom: 0;

    width: 100%;
  }